<template>
  <div>
    <el-col  class="item">
      <el-col :span="24" class="countdownBox">
        <span class="countdownDiv">
          <ProCountDown
            :startTime="startTime"
            :endTime="endTime"
          ></ProCountDown>
        </span>
      </el-col>
      <div class="imgWrap" @click="jumpDetail(info)">
        <img
          :alt="info.name | defaultName"
          :src="info.images ? info.images + picSuffix : defaultImage"
        />
      </div>

      <div class="priceBox">


        <span class="price"  v-if="info.numPrice && info.numPrice!=''"><strong>￥</strong>{{ info.auditUserStatus===0?"***":priceMsg}}</span>
          <span class="price"  v-else><strong>￥</strong> {{ info.auditUserStatus===0?"***": info.promotionProductRelationVO.price | capitalize}}</span>
          
        <!-- 
        <span class="price">
           {{ info.auditUserStatus===0?"***": info.promotionProductRelationVO.price | capitalize}}
         </span> -->
         <del class="delPrice">
          {{ info.auditUserStatus===0?"***": info.promotionProductRelationVO.originalPrice | capitalize}}
          </del>
      </div>
      <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
      <!-- <p class="msg">{{info.promotionProductRelationVO.name}}</p> -->
      <div class="validity">
        <span class="ProductLabel"
          >
          <b>已成团</b>
          {{ info.promotionProductRelationVO.perQuantity
          }}{{ info.promotionProductRelationVO.unit }}起拼/已拼{{
            info.promotionProductRelationVO.realQuantity
          }}{{ info.promotionProductRelationVO.unit }}</span
        >
      </div>
      <p class="msg">{{ info.promotionProductRelationVO.manufacturerName }}</p>
    </el-col>
  </div>
</template>
<style lang="less" scoped>
@import "~style/index.less";

.item {
  background: #fff;
  width: 228px;
  background: #fff;
  float: left;
  margin-right: 10px;
  margin-bottom: 15px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 10px;
  position: relative;
  cursor: pointer;
  .imgWrap {
    margin-top:30px;
    width: 208px;
    height: 208px;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      cursor: pointer;
      &:hover {
        box-shadow: 10px 6px 6px -4px #dedada;
        border-radius: 7px;
      }
    }
  }

  .shopName {
    color: #333;
    font-size: 15px;
    line-height: 30px;
    font-weight: 550;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .validity {
    line-height: 20px;
    font-size: 12px;
    color: #999;
  }
  .countdownBox {
    height: 26px;
    padding-top: 5px;
    float: none;
  }
  .ProductLabel {
    background-color: #fee9ea;
    border-color: #fee9ea;
    color: #ef2935;
    border-radius: 5px;
    border-width: 1px;
    font-size: 12px;
    border-style: solid;
    display: inline-block;
    width: 100%;
    text-align: left;
    b{
      float: left;
      border-radius: 5px;
      padding: 0 3px;
      background: #ff4d00;
      color: #fff;
      margin-right: 10px;
    }
  }
  .priceBox {
    line-height: 22px;
    font-size: 12px;
    color: #999;
    .price {
      color: #ff3333;
      font-size: 16px;
      font-weight: bold;
      display: inline-block;
      font-family: smallYuan;
    }
    .delPrice {
      padding-left: 10px;
      color: #929598;
      font-size: 12px;
    }

    .num {
      color: #929598;
      font-size: 12px;
      float: right;
    }
  }

  .msg {
    color: #929598;
    margin-top: 5px;
    font-size: 12px;
    text-indent: 3px;
    line-height: 20px;
    width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
  }
}
</style>

<script>
/**
 * 限时拼团
 */
const ProCountDown = () => import("components/product/ProCountDown.vue");
import defaultImage from "@/assets/defaultImage.png";
export default {
  name: "FlashGroupLi",
  data() {
    return {
      picSuffix: localStorage.getItem("productPic"),
      currentIndex: 0,
      //价格框显示隐藏
      status: false,
      num: 0,
      // pics: 1,
      defaultImage: defaultImage,
      promotionMap: "",
      numPriceArr:[],
          priceMsg:'',
    };
  },
  components: {
    ProCountDown,
  },
  props: {
    info: {
      type: Object,
    },
  },
  methods: {
    //跳转限时拼团商品详情
    jumpDetail(info) {
      // console.log('info: ', info);
      // this.$router.push({ name: "FlashGroupDetail", query: { promotionId:info.id } });
      const routeData = this.$router.resolve({ name: "FlashGroupDetail", query: { promotionId:info.id } });
      window.open(routeData.href, '_blank');
    },
  },
  created() {
    // console.log(this.info);
    this.endTime = this.info.endDate;
    this.startTime = this.info.beginDate;
    if (this.productType == "setMeal") {
      this.url = this.info.albumPics.split(",")[0];
    }

    if(this.info.numPrice && this.info.numPrice!=''){
      this.numPriceArr=JSON.parse(this.info.numPrice)
      // 倒序排序
      this.numPriceArr.sort(function(a, b) {  
        return b.num - a.num;  
      });  
      this.priceMsg=Number(this.numPriceArr[0].price).toFixed(2)+'起'
    }








  },
  updated() {},
};
</script>